import Footer from "../Footer";
import Header from "../Header";
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import { getActiveEvents, getAllAttendees, getAttendees } from "../../utils/apiUtils";
import { decryptData } from "../../utils/cryptoUtils";
import { Button, IconButton, Stack, Select, MenuItem, InputLabel, FormControl, TextField } from "@mui/material";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import Loader from "../../utils/Loader";
import axios from 'axios';
//MODAL
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
    overflowY: 'scroll',
  }
};

export default function CancelAtt() {
  const [events, setEvents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [event, setEvent] = useState('');
  const [att, setAtt] = useState('');
  const [attId, setAttId] = useState('');
  const [movedEvent, setMovedEvent] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [searchParam, setsearchParam] = useState([]);
  const [attendee, setAttendee] = useState({})
  const [cancelled, setCancelled] = useState(0)
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [userId, setUserId] = useState('');
  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openChange, setOpenChange] = useState(false);
  const handleOpenChange = () => setOpenChange(true);
  const handleCloseChange = () => setOpenChange(false);
  const [openEmail, setOpenEmail] = useState(false);
  const handleOpenEmail = () => setOpenEmail(true);
  const handleCloseEmail = () => setOpenEmail(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  // edit attendee modal
  const [showName, setShowName] = useState(false)
  const [showSurname, setShowSurname] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showPhone, setShowPhone] = useState(false)

  const url = 'https://dev.pmwevents.com'
  // const url = 'http://localhost:4100'


  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    getActiveEvents().then((data) => {
      setEvents(data)
    }).catch((err) => {
      console.log(`Could not get events: ${err}`)
    })
  }, [])

  const getAttende = (eventId) => {
    setAttendees([]);
    setShowLoader(true)
    if (eventId === '-SELECT EVENT-') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowAlert(true);
      setShowLoader(false)
      setTimeout(() => {
        setShowAlert(false);
      }, 2000)
    } else {
      setEvent(eventId)
      getAllAttendees(eventId)
        .then((data) => {
          // console.log(data)
          let cancel = 0;
          if (data.length === 0) {
            setMessage('No tickets bought for this event')
            setSeverity('warning')
            setShowAlert(true);
            setShowLoader(false)
            setTimeout(() => {
              setShowAlert(false);
            }, 2500)

          } else {
            
            const atts = data
            //DECRYPTS THE DATA
            for (let a = 0; a < atts.length; a++) {
              atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
              atts[a].email = decryptData(atts[a].email, process.env.REACT_APP_ATT_KEY)
              atts[a].phone = decryptData(atts[a].phone, process.env.REACT_APP_ATT_KEY)
              atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
              if (atts[a].status === "cancelled"){
                cancel ++;
              }  
            }
            setAttendees(atts)
            setCancelled(cancel)
            setShowLoader(false)
          }
        }).catch((err) => {
          console.log(`Could not get attendees: ${err}`)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowLoader(false)
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000)
        })
    }
  }

  // opens the modal to confirm cancelation
  const confirmCancel = (attendeeName, attendeeId) => {
    setAtt(attendeeName);
    setAttId(attendeeId);
    handleOpen();
  }

  // opens the modal to confirm move and select event
  const confirmMove = (attendeeName, attendeeId) => {
    setAtt(attendeeName);
    setAttId(attendeeId);
    handleOpenChange();
  }

  const confirmEmail = (attendeeName, attendeeId) => {
    setAtt(attendeeName);
    setAttId(attendeeId);
    handleOpenEmail();
  }

  const confirmEdit = (attendee) => {
    setAttendee(attendee)
    handleOpenEdit()
  }

  //cancels attendee
  const cancelAttendee = () => {
    let data = {
      userId: userId,
      attId: attId,
      eventId: event,
    }
    axios.post(`${url}/events/cancel-att`, data)
      .then((res) => {
        console.log(res.data)
        handleClose()
        getAttende(event)
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000)

      })

  }

  // moves attendee
  const moveAttendee = () => {
    if (movedEvent === '') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowModalAlert(true)
      setTimeout(() => {
        setShowModalAlert(false)
      }, 2000)
    } else {
      let data = {
        userId: userId,
        attId: attId,
        eventId: movedEvent,
      }
      axios.put(`${url}/events/move-att`, data)
        .then((res) => {
          handleCloseChange()
          getAttende(movedEvent)
        }).catch((err) => {
          console.log(err)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000)
        })
    }
  }

  // re-sends confirmation email
  const resendEmail = () => {
    setDisabled(true)
    let data = {
      userId: userId,
      attId: attId,
      eventId: event,
    }
    axios.post(`${url}/events/email`, data)
      .then((res) => {
        setMessage('Email sent')
        setSeverity('success')
        setShowModalAlert(true);
        setTimeout(() => {
          setShowModalAlert(false);
          setDisabled(false)
          handleCloseEmail()
        }, 2000)
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowModalAlert(true);
        setTimeout(() => {
          setShowModalAlert(false);
          setDisabled(false)
        }, 2000)
      })
  }
  //Search attendee
  const searchAtt = (param) => {
    let searchParam = param;
    let localAttendees = [];

    if (searchParam.length >= 2) {
      for (let a = 0; a < attendees.length; a++) {
        if (attendees[a].name.toLowerCase().includes(searchParam.toLowerCase())) {
          localAttendees.push(attendees[a]);
        }
      }
    }
    setsearchParam(localAttendees);
  }

  const handleAttendeee = (field, change) => {
    attendee[field] = change
  }

  const editAtt = () => {
    console.log('editing attendee')
    console.log(attendee)
    let data = {
      attendee: attendee,
      userId: userId,
    }
    setDisabled(true)
    axios.post(`${url}/events/edit-att`, data)
      .then((res) => {
        console.log(res.data)
        setMessage('Attendee Details changed')
        setSeverity('success')
        setShowModalAlert(true);
        setTimeout(() => {
          setShowModalAlert(false);
          setDisabled(false)
          handleCloseEdit()
        }, 2000)
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowModalAlert(true);
        setTimeout(() => {
          setShowModalAlert(false);
          setDisabled(false)
        }, 2000)
      })
  }


  return (
    <main className="events__management">
      <Header />
      <section className="download__main">
        <h2>Please select an active event to see attendees</h2>
        <select onChange={(e) => getAttende(e.target.value)}>
          <option>-SELECT EVENT-</option>
          {events.map((event) => {
            return (
              <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
            )
          })}
        </select>
        {showLoader ? <Loader /> : null}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
        {attendees.length > 0 ? (
          <>
            <h3>Current Attendees: <span>{attendees.length}</span></h3>
            <h3 style={{margin: '0 auto 1% auto'}}>Cancelled Attendees: <span>{cancelled}</span></h3>

            <input type='text' placeholder='Search By Name:' onChange={(e) => searchAtt(e.target.value)} />
            {searchParam.length > 0 ? (
              <table>
                <thead>
                  <th>Order ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Ticket Type</th>
                  <th>Delete</th>
                  <th>Change Event</th>
                  <th>Resend Ticket</th>
                  <th>Edit</th>
                </thead>
                <tbody>
                  {searchParam.map((att, index) => {
                    let backColor = "";
                    if (att.status === "cancelled") {
                      backColor = '#E8160C'
                    }
                    return (
                      <tr key={index} style={{ backgroundColor: backColor }}>
                        <td>{att.attendeeId}</td>
                        <td>{att.name}</td>
                        <td>{att.lastName}</td>
                        <td>{att.email}</td>
                        <td>{att.phone}</td>
                        <td>{att.ticketType}</td>
                        <td style={{ cursor: 'pointer' }}><IconButton><EventBusyIcon color='primary' fontSize="large" onClick={() => confirmCancel(att.name, att.attendeeId)} /></IconButton></td>
                        <td style={{ cursor: 'pointer' }}><IconButton><ChangeCircleIcon color='primary' fontSize="large" onClick={() => confirmMove(att.name, att.attendeeId)} /></IconButton></td>
                        <td style={{ cursor: 'pointer' }}><IconButton><EmailIcon color='primary' fontSize="large" onClick={() => confirmEmail(att.name, att.attendeeId)} /></IconButton></td>
                        <td style={{ cursor: 'pointer' }}><IconButton><EditIcon color='primary' fontSize="large" onClick={() => confirmEdit(att)} /></IconButton></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : null}
            <table>
              <thead>
                <th>Order ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Ticket Type</th>
                <th>Delete</th>
                <th>Change Event</th>
                <th>Resend Ticket</th>
                <th>Edit</th>
              </thead>
              <tbody>
                {attendees.map((att, index) => {
                  let backColor = "";
                  if (att.status === "cancelled") {
                    backColor = '#E8160C'
                  }

                  return (
                    <tr key={index} style={{ backgroundColor: backColor}}>
                      <td>{att.attendeeId}</td>
                      <td>{att.name}</td>
                      <td>{att.lastName}</td>
                      <td>{att.email}</td>
                      <td>{att.phone}</td>
                      <td>{att.ticketType}</td>
                      <td style={{ cursor: 'pointer' }}><IconButton><EventBusyIcon color='primary' fontSize="large" onClick={() => confirmCancel(att.name, att.attendeeId)} /></IconButton></td>
                      <td style={{ cursor: 'pointer' }}><IconButton><ChangeCircleIcon color='primary' fontSize="large" onClick={() => confirmMove(att.name, att.attendeeId)} /></IconButton></td>
                      <td style={{ cursor: 'pointer' }}><IconButton><EmailIcon color='primary' fontSize="large" onClick={() => confirmEmail(att.name, att.attendeeId)} /></IconButton></td>
                      <td style={{ cursor: 'pointer' }}><IconButton><EditIcon color='primary' fontSize="large" onClick={() => confirmEdit(att)} /></IconButton></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : null}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to cancel {att}?
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
              <Button color='error' variant='outlined' onClick={handleClose}>Return</Button>
              <Button color='success' variant='contained' onClick={cancelAttendee}>Delete</Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={openChange}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please select the event you want to move {att} to
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="select-label">Events</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                label="Events"
                value={movedEvent}
                onChange={(e) => setMovedEvent(e.target.value)}
              >
                {events.map((event) => {
                  return (
                    <MenuItem key={event.eventId} value={event.eventId}>{event.eventName}</MenuItem>

                  )
                })}
              </Select>
            </FormControl>
            {showModalAlert ? (
              <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
                {message}
              </Alert>
            ) : (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '2% auto' }}>
                <Button color='error' variant='outlined' onClick={handleCloseChange}>Return</Button>
                <Button color='success' variant='contained' onClick={moveAttendee}>Move</Button>
              </Stack>
            )}
          </Box>
        </Modal>
        {/* EMAIL MODAL */}
        <Modal
          open={openEmail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to re send the email to {att}?
            </Typography>
            {showModalAlert ? (
              <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
                {message}
              </Alert>
            ) : (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
                <Button color='error' variant='outlined' onClick={handleCloseEmail}>Return</Button>
                <Button color='success' variant='contained' onClick={resendEmail} disabled={disabled}>Re-send</Button>
              </Stack>
            )}
          </Box>
        </Modal>
        {/* END OF EMAIL MODAL */}
        {/* EDIT MODAL */}
        <Modal
          open={openEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to edit {attendee.name} {attendee.lastName}?
            </Typography>
            <Stack>
              <Typography variant="body1">Name: {attendee.name} <EditIcon color='primary' fontSize="small" onClick={() => setShowName(!showName)} /></Typography>
              {showName ? (
                <TextField label="name" variant="standard" onChange={(e) => handleAttendeee('name', e.target.value)} />
              ) : null}
              <Typography variant="body1">Surname: {attendee.lastName} <EditIcon color='primary' fontSize="small" onClick={() => setShowSurname(!showSurname)} /></Typography>
              {showSurname ? (
                <TextField label="surname" variant="standard" onChange={(e) => handleAttendeee('lastName', e.target.value)} />
              ) : null}
              <Typography variant="body1">Email: {attendee.email} <EditIcon color='primary' fontSize="small" onClick={() => setShowEmail(!showEmail)} /></Typography>
              {showEmail ? (
                <TextField label="Email" variant="standard" onChange={(e) => handleAttendeee('email', e.target.value)} />
              ) : null}

              <Typography variant="body1">Phone: {attendee.phone} <EditIcon color='primary' fontSize="small" onClick={() => setShowPhone(!showPhone)} /></Typography>
              {showPhone ? (
                <TextField label="Phone" variant="standard" onChange={(e) => handleAttendeee('phone', e.target.value)} />
              ) : null}
            </Stack>
            {showModalAlert ? (
              <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
                {message}
              </Alert>
            ) : (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
                <Button color='error' variant='outlined' onClick={handleCloseEdit}>Return</Button>
                <Button color='success' variant='contained' onClick={editAtt} disabled={disabled}>Change Details</Button>
              </Stack>
            )}
          </Box>
        </Modal>
        {/* END OF EDIT MODAL */}
      </section>
      <Footer />
    </main>
  )
}